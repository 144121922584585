import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Layout from "components/Layout"
import ArtistCard from "components/ArtistCard"

const Artists = ({ artists, meta }) => (
  <>
    <Helmet
      title={`Artists | Designer | Photographer`}
      titleTemplate={`%s | ${meta.title}`}
      meta={[
        {
          name: `description`,
          content: meta.description,
        },
        {
          property: `og:title`,
          content: `Artists | Designer | Photographer`,
        },
        {
          property: `og:description`,
          content: meta.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: meta.author,
        },
        {
          name: `twitter:title`,
          content: meta.title,
        },
        {
          name: `twitter:description`,
          content: meta.description,
        },
      ].concat(meta)}
    />
    <Layout>
      <>
        {artists.map((artist, i) => (
          <ArtistCard
            key={i}
            profession={artist.node.profession}
            title={artist.node.titel}
            thumbnail={artist.node.hero_image}
            uid={artist.node._meta.uid}
          />
        ))}
      </>
    </Layout>
  </>
)

export default ({ data }) => {
  const artists = data.prismic.allArtists.edges
  const meta = data.site.siteMetadata
  if (!artists) return null

  return <Artists artists={artists} meta={meta} />
}

Artists.propTypes = {
  artists: PropTypes.array.isRequired,
}

export const query = graphql`
  {
    prismic {
      allArtists {
        edges {
          node {
            titel
            profession
            hero_image
            _meta {
              uid
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
