import React from "react"
import { Link } from "gatsby"
import { RichText } from "prismic-reactjs"
import styled from "@emotion/styled"
import dimensions from "styles/dimensions"
import PropTypes from "prop-types"

const ArtistCardContainer = styled(Link)`
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 120px;
  transition: all 150ms ease-in-out;

  text-decoration: none;
  color: currentColor;

  & :nth-of-type(odd) {
    flex-direction: row;
  }

  @media (max-width: 950px) {
    flex-direction: column-reverse;

    & :nth-of-type(odd) {
      flex-direction: column-reverse;
    }
  }

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    margin-bottom: 56px;
  }
`

const ArtistCardContent = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 50%;
  overflow: hidden;
  background: white;
  padding: 0 4em;

  h1,
  h2,
  h3,
  h6 {
    width: 100%;
  }

  h1,
  h3,
  h2 {
    font-family: "NewEddy", "Inter", sans-serif;
    font-weight: normal;
    margin: 0;
    font-size: 3em;
  }

  h6 {
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 120%;
  }

  p {
    margin-top: 24px;
  }

  @media (max-width: 950px) {
    width: 100%;
    padding: 32px 0 0 0;
  }
`

const ArtistCardImageContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 50%;
  overflow: hidden;
  position: relative;

  @media (max-width: 950px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }

  img {
    max-width: 100%;
    max-height: 95vh;
  }
`

const ArtistCard = ({ profession, title, thumbnail, uid }) => (
  <ArtistCardContainer to={`/artist/${uid}`}>
    <ArtistCardContent className="ArtistCardContent">
      {RichText.render(title)}
      {profession && <h6>{profession[0].text}</h6>}
    </ArtistCardContent>
    <ArtistCardImageContainer className="ArtistCardImageContainer imageWrapper">
      {thumbnail && <img src={thumbnail.url} alt={title[0].text} />}
    </ArtistCardImageContainer>
  </ArtistCardContainer>
)

export default ArtistCard

ArtistCard.propTypes = {
  title: PropTypes.array.isRequired,
}
